import { Link } from 'gatsby';
import * as React from 'react';
import { displayFlex, flexColumn } from '../styles/class-names';
import { formatDateString } from '../utils/date';

export default function WritingListItem({
	date,
	title,
	url,
	urlTitle,
}: {
	date: string;
	title: string;
	url: string;
	urlTitle: string;
}) {
	const d = new Date(date);
	const formatDate = formatDateString(date);
	return (
		<li className={`${displayFlex} ${flexColumn}`}>
			<time>{formatDate}</time>
			<h3><Link to={url} title={urlTitle}>
				{title}
			</Link></h3>
		</li>
	);
}
