import * as React from 'react';
import { graphql } from 'gatsby';
import LayoutHeaderAndFooter from '../components/layout-header-and-footer';
import WritingListItem from '../components/writing-list-item';
import { noBullet, displayFlex, flexColumn, padding10 } from '../styles/class-names';
import Seo from '../components/seo';

export default function IndexPage({ data }: any) {
	const image = data.site.siteMetadata.siteUrl + '/img/photos/profiles/profile-covid.jpg';
	return (
		<LayoutHeaderAndFooter>
			<Seo title="Michael J. Bennett's Personal Website" description="Here you'll find Michael's latest updates, and links to some of his work" imagePath={image} url="https://michaeljbennett.info"/>
			<nav className={`${displayFlex} ${flexColumn}`}>
				<h3 className={padding10}>Posts</h3>
				<ul className={`${noBullet} ${padding10}`}>
					{data.allMarkdownRemark.edges.map(({ node }: any, i: number) => {
						const {
							excerpt,
							fields: { slug },
							frontmatter: { date, title },
						} = node;
						return (
							<WritingListItem
								key={`wli-index-${i}`}
								date={date}
								title={title}
								url={slug}
								urlTitle={excerpt}
							/>
						);
					})}
				</ul>
			</nav>
		</LayoutHeaderAndFooter>
	);
}

export const query = graphql`
	query {
		allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
			edges {
				node {
					fields {
						slug
					}
					id
					frontmatter {
						date
						title
					}
					excerpt
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`
